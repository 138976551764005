import React, { useRef } from "react";
import useScrollSpy from "react-use-scrollspy";
import { About, Projects, Education } from "./Sections";

const MyScrollspy = (probs) => {
  const sectionRefs = [useRef(null), useRef(null), useRef(null)];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -80,
  });
  const colorOverride = {
    color: "White",
  };

  return (
    <div>
      <nav className="Scrollspy-navigation">
        <a
          style={colorOverride}
          href="#about"
          className={
            activeSection === 0
              ? "Scrollspy-navigation-item Scrollspy-navigation-item--active"
              : "Scrollspy-navigation-item"
          }
        >
          About me
        </a>
        <a
          style={colorOverride}
          href="#projects"
          className={
            activeSection === 1
              ? "Scrollspy-navigation-item Scrollspy-navigation-item--active"
              : "Scrollspy-navigation-item"
          }
        >
          Projects
        </a>
        <a
          style={colorOverride}
          href="#education"
          className={
            activeSection === 2
              ? "Scrollspy-navigation-item Scrollspy-navigation-item--active"
              : "Scrollspy-navigation-item"
          }
        >
          Education
        </a>
      </nav>

      <section id="about" className="Scrollspy-section" ref={sectionRefs[0]}>
        &nbsp;
        <About></About>
      </section>
      <section id="projects" className="Scrollspy-section" ref={sectionRefs[1]}>
        &nbsp;
        <Projects></Projects>
      </section>
      <section
        id="education"
        className="Scrollspy-section"
        ref={sectionRefs[2]}
      >
        &nbsp;
        <Education></Education>
      </section>
    </div>
  );
};

export default MyScrollspy;
