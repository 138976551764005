import React from "react";
import "./dist/App.css";
import Footer from "./components/Footer";
import MyScrollspy from "./components/MyScrollspy";
import Hero from "./components/Hero";

const App = () => {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Hero></Hero>
      <MyScrollspy></MyScrollspy>
      <Footer name="Drew Weber v.01" />
    </div>
  );
};

export default App;
