//import TextLoop from 'react-text-loop';
import React from "react";
import LazyHero from "react-lazy-hero";
import Typical from "react-typical";

const Hero = () => {
  return (
    <LazyHero
      imageSrc={"https://lh3.googleusercontent.com/fife/ABSRlIr4W_ydLz6zzTxgwQX2JFSer4G5kTlEVzeIdGYE8aLdEvQIf7Xr7nS9nhU-G5rwif6tILi2oBjSXZISRmG1g8cdlCN6XFM-ppw1yg2y40qJ5L4nSMl74opbwsctR8XRar0CzGdkF6ocGOoxmHfHPYniSbr0NGmrYUaSphkT3IaJOtx0n-xqH2ePg7cWS4mR7cukYtI1U2LOq4Y_06d_EhhoR4LOglO0yOZAzSi8CQKLsoMavGeUYg-glyZC8BFSR2Ubrl_rszIiQssoqHVVofBHQRMD0iS1YlUCK-yoEj8IpAdpoBCItWS5d_dKlJ8Tz-wDdo9T3i0NB3SQKP-C0pKHbzYAy6qimc05NT93jRKMGH03cRUcJf2SJj6SRY9h0g4i0AMxxP6RsTaETqRszHXBK_Vxm3ICzGT8FFQDbih1teO8Z1lnRWc4AyfMAM74jBaWqSgL_IBS5XWfu5qU7XyKXmlY6V4Yr3HLbgklilglKgFwMHdbf37TuAoV7mmsCo96ilZNyrVaW0H8DrooDdiwdLU-BaWa7G5pZnRV1wgbkEwc9Xt0RzzvNLDP3Md_MtRCVrkNTKq4il2PdE3GhwtOF9p8LBaqNMnXTIAXnZJVdwX3m2q7JR08O4PfZTnnXIGsnspS9BLCEI3IN6CNvHRKYB2TZvTf0OR6SCAmtbaSnpp0JVPNvb8msGlCaU9OMAmOeIL4j-BvWQoJQMU-0CXdOQeidtwUiPY5=s1120-w1120-h380-no?authuser=0"}
      parallaxOffset="1"
      transitionDuration="500"
    >
      <h1>Drew Weber</h1>
      <p class="Typer">
        I'm a{" "}
        <Typical
          wrapper="b"
          loop={Infinity}
          steps={[
            "computer scientist",
            1500,
            "programmer",
            1000,
            "developer",
            500,
            "dreamer",
            750,
            "C# fanatic",
            500,
          ]}
        />
        .
      </p>
    </LazyHero>
  );
};

export default Hero;
