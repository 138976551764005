import React from "react";

export const About = () => {
  return (
    <div class="White-Section">
      <h1>About Me</h1>
    </div>
  );
};

export const Projects = () => {
  return (
    <div class="Black-Section">
      <h1>Projects</h1>
      <p></p>
    </div>
  );
};

export const Education = () => {
  return (
    <div class="White-Section">
      <h1>Education</h1>
    </div>
  );
};
